// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getViews(params) {
    return Api().get('views', { params });
  },
  deleteAll() {
    return Api().delete('views');
  },
};

<!-- © 2021 Tfarraj -->
<template>
  <div ref="videoContainer" class="shadow-lg mx-auto max-w-full size">
    <video
      id="video"
      ref="videoPlayer"
      class="w-full video-ratio"
      crossorigin="anonymous"
      playsinline
      autoplay
    ></video>
  </div>
</template>
<script
  type="text/javascript"
  src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"
></script>
<script
  type="text/javascript"
  src="https://imasdk.googleapis.com/js/sdkloader/ima3_dai.js"
></script>
<script>
var crd;
// var geooptions = {
//   enableHighAccuracy: true,
//   timeout: 5000,
//   maximumAge: 60000,
// };
//
// function success(pos) {
//   crd = pos.coords;
//
//   console.log('Your current position is:');
//   console.log(`Latitude : ${crd.latitude}`);
//   console.log(`Longitude: ${crd.longitude}`);
//   console.log(`More or less ${crd.accuracy} meters.`);
// }
//
// function error(err) {
//   console.warn(`ERROR(${err.code}): ${err.message}`);
// }
//
// navigator.geolocation.getCurrentPosition(success, error, geooptions);

//console.log("self.position: "+ crd.latitude + " " + crd.latitude );
import Config from '@/config';
import VideoService from '@/services/VideoService';
import ViewService from '@/services/ViewService';
import muxjs from 'mux.js';
import { mapGetters } from 'vuex';
import lifecycle from 'page-lifecycle';

export default {
  props: {
    manifestUrl: {
      type: String,
      required: true,
    },
    licenseServer: {
      type: String,
      required: true,
    },
    posterUrl: {
      type: String,
      required: false,
      default: '',
    },
    contentTitle: {
      type: String,
      required: false,
      default: '',
    },
    pageUrl: {
      type: String,
      required: false,
      default: '',
    },
    uaStatus: {
      type: Boolean,
      required: false,
    },
    vidId: {
      type: String,
      required: false,
      default: '',
    },
    duration: {
      type: Number,
      required: false,
      default: 0,
    },
    vcatName: {
      type: String,
      required: false,
      default: '',
    },
    view: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    // https://github.com/shaka-project/shaka-player/issues/1927
    window.muxjs = muxjs;
    const shaka = require('shaka-player/dist/shaka-player.ui.js');
    shaka.polyfill.installAll();
    const video = this.$refs.videoPlayer;
    const player = new shaka.Player(video);

    const adManager = player.getAdManager();

    const ui = new shaka.ui.Overlay(
      player,
      this.$refs.videoContainer,
      this.$refs.videoPlayer,
    );

    const container = ui.getControls().getClientSideAdContainer();

    player.configure({
      // drm: {
      //   servers: { "com.widevine.alpha": this.licenseServer },
      // },
      manifest: {
        hls: {
          useFullSegmentsForStartTime: true,
        },
      },
    });

    shaka.media.ManifestParser.registerParserByExtension(
      'm3u8',
      shaka.hls.HlsParser,
    );
    shaka.media.ManifestParser.registerParserByMime(
      'Application/vnd.apple.mpegurl',
      shaka.hls.HlsParser,
    );
    shaka.media.ManifestParser.registerParserByMime(
      'application/x-mpegURL',
      shaka.hls.HlsParser,
    );
    let lastPlaybackPosition = localStorage.getItem(this.vidId);

    const string_to_array = function (str) {
      var pre = str.replace(/[&\/\\#,+()$~%.'"“”:*?<>{}]/g, '');
      return pre.trim().split(' ');
    };
    const token = this.getToken;
    // This is used to add auth headers in shaka-player
    player
      .getNetworkingEngine()
      .registerRequestFilter(function (type, request) {
        // This is used to make sure auth headers only added to Tadafuq API
        if (request.uris[0].startsWith(Config.apiUrl) && token) {
          request.headers['Authorization'] = `Bearer ${token}`;
        }
      });
    // get poster(thumbnail)
    video.poster = await VideoService.getThumbnail(this.vidId);

    if (this.duration - lastPlaybackPosition < 1) {
      lastPlaybackPosition = 0;
    }

    player
      .load(this.manifestUrl, lastPlaybackPosition)

      .then(() => {
        adManager.initClientSide(container, this.$refs.videoPlayer);
        google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
        const adsRequest = new google.ima.AdsRequest();

        //         var page = Config.uiUrl + this.pageUrl || null;
        //
        //         var keywords =
        //           encodeURIComponent(string_to_array(this.contentTitle)) || null;
        //         var content_title = encodeURIComponent(this.contentTitle) || null;
        //         var category = encodeURIComponent(this.vcatName) || null;
        //
        //         console.log("TAG: " + page, keywords, content_title, category);

        if (this.uaStatus) {
          // adsRequest.adTagUrl = `${Config.mediaUrl}/?dom=${Config.domain}`;
          // adsRequest.adTagUrl =
          //   `${Config.mediaUrl}/?dom=${Config.domain}&latitude=` +
          //   self.position.latitude +
          //   "&longitude=" +
          //   self.position.longitude +
          //   "&page=" +
          //   page +
          //   "&content_title=" +
          //   content_title +
          //   "&category=" +
          //   category +
          //   "&keywords=" +
          //   keywords;

          // adsRequest.adTagUrl = "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&cmsid=496&vid=short_onecue&correlator=";

          // adsRequest.adTagUrl = `${Config.mediaUrl}/?dom=${Config.domain}&latitude=` +
          // crd.latitude +
          // "&longitude=" +
          // crd.longitude;

          //adsRequest.adTagUrl =  `${Config.advertisingUrl}/vmap.xml`;

          adsRequest.adTagUrl = `${Config.advertisingUrl}/vmappostroll.xml`;

          //console.log("self.position: "+ crd.latitude + ", " + crd.longitude );
        } else {
          // adsRequest.adTagUrl =
          //   `${Config.mediaUrl}/?dom=${Config.domain}&latitude=` +
          //   self.position.latitude +
          //   "&longitude=" +
          //   self.position.longitude +
          //   "&page=" +
          //   page +
          //   "&content_title=" +
          //   content_title +
          //   "&category=" +
          //   category +
          //   "&keywords=" +
          //   keywords;

          // adsRequest.adTagUrl =
          // `${Config.mediaUrl}/?dom=${Config.domain}&latitude=` +
          // crd.latitude +
          // "&longitude=" +
          // crd.longitude;

          adsRequest.adTagUrl = `${Config.mediaUrl}/?dom=${Config.domain}`;

          // adsRequest.adTagUrl =
          // `${Config.advertisingUrl}/vmappostroll.xml`;

          //console.log("self.position: "+ crd.latitude + ", " + crd.longitude );

          //VMAP
          //  adsRequest.adTagUrl =
          // "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&cmsid=496&vid=short_onecue&correlator=";

          // adsRequest.adTagUrl =
          //   "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=";
        }

        adsRequest.setAdWillAutoPlay = true;
        adsRequest.contentTitle = this.contentTitle;
        adsRequest.pageUrl = Config.uiUrl + this.pageUrl;
        adsRequest.setContinuousPlayback = true;
        adsRequest.contentDuration = null;
        adsRequest.contentKeywords = string_to_array(this.contentTitle) || null;
        adsRequest.forceNonLinearFullSlot = true;
        adsRequest.linearAdSlotWidth = video.videoWidth;
        adsRequest.linearAdSlotHeight = video.videoHeight;
        adsRequest.nonLinearAdSlotWidth = video.videoWidth;
        adsRequest.nonLinearAdSlotHeight = video.videoHeight;
        adManager.enableIosSkippableAds = true;
        adManager.requestClientSideAds(adsRequest);
      })

      .catch(this.onError); // onError is executed if the asynchronous load fails.

    const videop = document.getElementById('video');
    const uip = videop['ui'];
    const config = {
      seekBarColors: {
        base: 'rgba(255, 255, 255, 0.3)',
        buffered: 'rgba(255, 255, 255, 0.54)',
        played: 'rgb(50, 133, 168)',
      },
      controlPanelElements: [
        'play_pause',
        'time_and_duration',
        'spacer',
        'overflow_menu',
        'rewind',
        'fast_forward',
        'mute',
        'volume',
        'fullscreen',
        'airplay',
      ],
      overflowMenuButtons: [
        'cast',
        'quality',
        'playback_rate',
        'picture_in_picture',
        'loop',
      ],
    };
    uip.configure(config);
    const page = this;
    lifecycle.addEventListener('statechange', page.handlePageEvent);
  },
  async beforeDestroy() {
    const page = this;
    await this.createView();
    lifecycle.removeEventListener('statechange', page.handlePageEvent);
  },
  // created() {
  //   player.addEventListener("beforeunload", this.handler);
  // },
  methods: {
    async handlePageEvent(event) {
      if (
        event.originalEvent.type === 'pagehide' &&
        event.newState === 'hidden'
      ) {
        await this.createView();
      }
    },
    async createView() {
      const video = this.$refs.videoPlayer;
      localStorage.setItem(this.vidId, video.currentTime);
      const watchPercentage = (video.currentTime / video.duration) * 100;
      try {
        await VideoService.createView(this.vidId, {
          watchPercentage,
        });
      } catch (error) {
        // console.log(err);
      }
    },
    onError(error) {
      //console.error("Error code", error.code, "object", error);
    },
    // handler: function handler(event) {
    //   player.unload();
    //   player.destroy();
    //   console.error("player.destroyed");
    // },
  },
  data: {
    location: null,
    gettingLocation: false,
    errorStr: null,
  },
  computed: {
    ...mapGetters(['getToken', 'getUser']),
  },
  //   created() {
  //     //do we support geolocation
  //     if (!("geolocation" in navigator)) {
  //       this.errorStr = "Geolocation is not available.";
  //       return;
  //     }
  //
  //     this.gettingLocation = true;
  //     // get position
  //     navigator.geolocation.getCurrentPosition(
  //       (pos) => {
  //         this.gettingLocation = false;
  //         this.location = pos;
  //         console.log("LAT / LON: " + JSON.stringify(this.location));
  //       },
  //       (err) => {
  //         this.gettingLocation = false;
  //         this.errorStr = err.message;
  //       }
  //     );
  //   },
};
</script>

<style>
@import '../../node_modules/shaka-player/dist/controls.css'; /* Shaka player CSS import */
/* .size {
  max-height: 100%;
  max-width: 100%;
} */
</style>

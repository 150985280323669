import { DateTime, Settings, Duration } from 'luxon';

const units = ['year', 'month', 'day', 'hour', 'minute', 'second'];

export default {
  methods: {
    formatDate(date) {
      if (date) {
        const dateTime = DateTime.fromISO(date);
        const diff = dateTime.diffNow().shiftTo(...units);
        const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
        const relativeFormatter = new Intl.RelativeTimeFormat(
          Settings.defaultLocale,
          {
            numeric: 'auto',
          },
        );
        return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
      }
      return '';
    },
    hourDataNew(date) {
      const dateTime = DateTime.fromISO(date);
      const diff = DateTime.now().diff(dateTime, 'hours');
      return Math.floor(diff.toObject().hours);
    },
    secondsToFormattedDuration(secs) {
      const duration = Duration.fromObject({ second: secs });
      return duration.toFormat('hh:mm:ss');
    },
  },
};
